import React, { useState, useEffect, useRef } from 'react';
// Importing styled-components for styling
import {styled, createGlobalStyle, ThemeProvider} from 'styled-components';
import { FaGithub, FaBars } from 'react-icons/fa'; // Importing GitHub icon from react-icons

const lightTheme = {
    body: '#FFF',
    text: '#363537',
    toggleBorder: '#FFF',
    background: '#363537',
}

const darkTheme = {
    body: '#363537',
    text: '#FAFAFA',
    toggleBorder: '#6B8096',
    background: '#999',
}


const Title = styled.h2`
  text-align: center;
  font-size: 2em;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const GlobalStyle = createGlobalStyle`
  body {
    font-size: 25px;
    background-color: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: all 0.3s ease;
  }


  select {
		font-size: 0.8em;
		padding: 0.1em;
		margin: 0.2em 0;
		border: 1px solid ${({ theme }) => theme.body === '#FFF' ? '#ccc' : '#777'};
		border-radius: 4px;
		background-color: ${({ theme }) => theme.body === '#FFF' ? '#f4f4f4' : '#555'};
		color: ${({ theme }) => theme.text};

		&:focus {
			outline: none;
			border-color: ${({ theme }) => theme.body === '#FFF' ? '#9b9b9b' : '#aaa'};
		}
	}
  @media (max-width: 768px) {
    body {
      font-size: 20px;
    }

    select {
      font-size: 0.9em;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh; // This takes the full height of the viewport
  overflow-y: auto;
  padding: 20px;
  ox-sizing: border-box;
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 5px;
  }
`;

const ChatWindow = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.body};
  border-radius: 8px;
  box-shadow: 0 2px 10px ${({ theme }) => theme.body === '#FFF' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)'};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: auto;
  max-width: 600px;
  @media (max-width: 768px) {
    max-width: none;
    border-radius: 0;
  }
`;

const TextField = styled.div`
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.text};
`;

const TextInputBox = styled.textarea`
  background: ${({ theme }) => theme.body === '#FFF' ? '#f4f4f4' : '#555'};
  border: 2px solid ${({ theme }) => theme.body === '#FFF' ? '#ccc' : '#777'};
  border-radius: 4px;
  padding: 10px;
  font-family: 'Cormorant Garamond', serif;
  font-size: 0.8em;
  width: 95%;
  min-height: 100px;
  max-height: 200px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s;
  color: ${({ theme }) => theme.text};

  &:focus {
    border-color: ${({ theme }) => theme.body === '#FFF' ? '#9b9b9b' : '#aaa'};
    outline: none;
  }
  @media (max-width: 768px) {
    height: 80px;
  }
`;

const NameInputBox = styled.input`
  background: ${({ theme }) => theme.body === '#FFF' ? '#f4f4f4' : '#555'};
  border: 2px solid ${({ theme }) => theme.body === '#FFF' ? '#ccc' : '#777'};
  border-radius: 4px;
  padding: 10px;
  font-family: 'Cormorant Garamond', serif;
  font-size: 0.8em;
  width: 60%;
  margin-right: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s;
  color: ${({ theme }) => theme.text};

  &:focus {
    border-color: ${({ theme }) => theme.body === '#FFF' ? '#9b9b9b' : '#aaa'};
    outline: none;
  }
`;

const StyledButton = styled.button`
  background-image: linear-gradient(to right, #f6d365 0%, #fda085 100%);
  border: none;
  border-radius: 20px;
  padding: 3px 20px;
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;

const ResponseContainer = styled.div`
  min-height: 150px;
  max-height: 300px;
  overflow-y: auto;
  background: ${({ theme }) => theme.body === '#FFF' ? '#f9f9f9' : '#444'};
  padding: 10px;
  flex-direction: column-reverse;
  border-radius: 4px;
  margin: 10px 0;
  box-shadow: inset 0 0 5px ${({ theme }) => theme.body === '#FFF' ? 'rgba(0,0,0,0.2)' : 'rgba(255,255,255,0.1)'};
  position: relative;
  overflow-y: auto;
  color: ${({ theme }) => theme.text};
`;

const SidebarToggle = styled.button`
  background-image: linear-gradient(to right, #f6d365 0%, #fda085 100%);
  position: fixed;
  left: 10px;
  top: 40px;
  border: none;
  border-radius: 50%;
  padding: 0.5em;
  font-size: 0.7em;
  color: white;
  z-index: 100;
  display: none;

  @media (max-width: 1000px) {
    display: block;
  }
`;

const StyledBanner = styled.img`
  width: 100%;
  max-height: 130px;
  object-fit: cover;
  filter: ${({ theme }) => theme.body === '#363537' ? 'invert(1)' : 'none'};
  transition: filter 0.3s ease;
`;
      
const Sidebar = styled.div`
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  max-width: 200px;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.body};
  padding: 20px;
  padding-top: 80px;
  box-shadow: 2px 0 5px ${({ theme }) => theme.body === '#FFF' ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.1)'};
  display: flex;
  transition: transform 0.3s ease-in-out;
  flex-direction: column;
  color: ${({ theme }) => theme.text};

  @media (max-width: 1000px) {
    max-width: 100%;
    transform: ${props => props.$isVisible ? 'translateX(0)' : 'translateX(-100%)'};
    left: 0;
    top: 0;
  }
`;

const StyledLink = styled.a`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.body === '#FFF' ? '#0077ff' : '#66b3ff'};
  
  &:hover {
    text-decoration: underline;
  }
  
  svg {
    margin-right: 5px;
  }
`;

const StyledListLink = styled(StyledLink)`
  margin-bottom: 0px;
`;

const DarkModeToggle = styled.button`
  position: fixed;
  right: 10px;
  top: 10px;
  background: none;
  color: ${({ theme }) => theme.text};
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  z-index: 100;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const ScoreInfoContainer = styled(TextField)`
  margin-top: 20px;
  padding: 10px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.body === '#FFF' ? '#f0f0f0' : '#2a2a2a'};
  box-shadow: 0 2px 5px ${({ theme }) => theme.body === '#FFF' ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.1)'};
`;


const LeaderboardContainer = styled.div`
  margin-top: 20px;
  background: ${({ theme }) => theme.body === '#FFF' ? '#f9f9f9' : '#444'};
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 5px ${({ theme }) => theme.body === '#FFF' ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.1)'};
`;

const LeaderboardToggle = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.text};
  cursor: pointer;
  font-size: 0.6em;
  padding: 5px;
  display: flex;
  align-items: center;
  
  &:hover {
    text-decoration: underline;
  }
`;

const LeaderboardTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  
  th, td {
    border: 1px solid ${({ theme }) => theme.body === '#FFF' ? '#ddd' : '#555'};
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: ${({ theme }) => theme.body === '#FFF' ? '#f2f2f2' : '#333'};
  }
`;


function ChatInterface() {
  const [userName, setUserName] = useState('');
  const [requestId, setRequestId] = useState(null);
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState('');
  const [secretCode, setSecretCode] = useState(null);
  const [successInfo, setSuccessInfo] = useState(null);
  const abortControllerRef = useRef(new AbortController());
  const firstChunkProcessedRef = useRef(false);

  const [promptLevels, setPromptLevels] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedPromptLevel, setSelectedPromptLevel] = useState('');
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [hasMessageBeenSend, setHasMessageBeenSend] = useState(false);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [leaderboardDataIsLoading, setLeaderboardDataIsLoading] = useState(false);
  const [isLeaderboardExpanded, setIsLeaderboardExpanded] = useState(false);
  const [theme, setTheme] = useState('dark');
  const themeToggler = () => {
    theme === 'light' ? setTheme('dark') : setTheme('light');
  };

  const toggleLeaderboard = async () => {
      const newExpandedState = !isLeaderboardExpanded;
      if (newExpandedState) {
            await fetchLeaderboard();
          }
      setIsLeaderboardExpanded(newExpandedState);
  };

  useEffect(() => {
    if (isLeaderboardExpanded) {
      fetchLeaderboard();
    }
  }, [selectedModel, selectedPromptLevel, isLeaderboardExpanded]);

  const handleNameSubmit = async () => {
    if (userName.trim() === '') {
      alert('Please enter a valid name.');
      return;
    }

    try {
      const response = await fetch('/api/set-leaderboard-name', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: userName, request_id: requestId }),
      });
      setSuccessInfo(null);

      if (!response.ok) {
        alert('Failed to add your name to the leaderboard. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting name:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const fetchLeaderboard = async () => {
      setLeaderboardDataIsLoading(true);
      const response = await fetch(`/api/leaderboard?prompt_level=${selectedPromptLevel}`);
      if (!response.ok) {
        throw new Error('Failed to fetch leaderboard');
      }
      const data = await response.json();
      setLeaderboardData(data);
      setLeaderboardDataIsLoading(false);
  };


  const inputRef = useRef(null); // Ref for the input element

	const responseContainerRef = useRef(null);
  const responseEndRef = useRef(null); // Ref for the element at the end of the messages
  const scoreInfoRef = useRef(null);

  const toggleSidebar = () => {
    console.log('toggleSidebar');
    setSidebarVisible(!sidebarVisible);
  }

  const scrollToElement = (element) => {
    if (element && element.current) {
      element.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  const scrollToBottom = () => {
    if (responseContainerRef.current && responseEndRef.current) {
      const scrollContainer = responseContainerRef.current;
      const scrollElement = responseEndRef.current;

      const containerHeight = scrollContainer.clientHeight;
      const scrollHeight = scrollContainer.scrollHeight;
      const scrollTop = scrollContainer.scrollTop;
      const elementOffset = scrollElement.offsetTop;

      if (elementOffset > scrollTop + containerHeight - 100) {
        scrollContainer.scrollTop = scrollHeight - containerHeight;
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (successInfo) {
        scrollToElement(scoreInfoRef);
      } else {
        scrollToBottom();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [successInfo]);

  useEffect(() => {
    scrollToBottom();
  }, [response]);

  useEffect(() => {
    if (successInfo && scoreInfoRef.current) {
      scrollToElement(scoreInfoRef);
    }
  }, [successInfo]);


  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Set focus when component mounts
    }
  }, []);

  useEffect(() => {
    const fetchSchema = async () => {
      const response = await fetch('/api/config/schema');
      const schema = await response.json();

      const promptLevelOptions = schema.$defs.PromptLevel.enum;
      setPromptLevels(promptLevelOptions);
      setSelectedPromptLevel(promptLevelOptions[0]);
    };

    fetchSchema();
  }, []);

  const handleSendMessage = () => {
    setRequestId(null);
    setSuccessInfo(null);
    setSecretCode(null);
    sendMessage();
    window.scrollTo({
      top: inputRef.current.offsetTop,
            behavior: 'smooth'
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const formatResponse = (text, code) => {
    if (!text) return null; // Return null if the response is empty

    const formattedText = text.split('\n').map((line, lineIndex) => {
          if (!line) return <br key={lineIndex} />; // To handle empty lines within the response

          const parts = line.split(new RegExp(`(${code})`, 'gi'));
          return (
                  <span key={lineIndex}>
                    {parts.map((part, partIndex) => 
                                part.toLowerCase() === code.toLowerCase() ? <strong key={partIndex}>{part}</strong> : part
                              )}
                    {lineIndex < text.split('\n').length - 1 && <br />} {/* Add a <br> unless it's the last line */}
                  </span>
                );
        });

    return <>{formattedText}</>; // Return formatted text wrapped in a fragment
  };
  const sendMessage = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    setResponse('');
    setSecretCode(null);
    setSuccessInfo(null);
    firstChunkProcessedRef.current = false;
    try {
      const requestBody = {
        prompt: message,
        prompt_level: selectedPromptLevel,
        stream: true
      };

      const response = await fetch('/api/send-message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
        signal: abortControllerRef.current.signal
      });

      if (response.body) {
        const reader = response.body.getReader();
        let isFirstChunk = true;
        let secretCode = null;
        let fullText = '';
        let jsonBuffer = '';
        let isCollectingJson = false;

        const stream = new ReadableStream({
          async start(controller) {
            try {
              while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                if (isFirstChunk) {
                  const structuredResponse = JSON.parse(new TextDecoder().decode(value));
                  setSecretCode(structuredResponse.secret_code);
                  secretCode = structuredResponse.secret_code;
                  setRequestId(structuredResponse.request_id);
                  isFirstChunk = false;
                  continue;
                }

                const text = new TextDecoder().decode(value, { stream: true });
                fullText += text;

                if (!isCollectingJson && fullText.includes(secretCode)) {
                  const secretCodeIndex = fullText.indexOf(secretCode);
                  const textBeforeCode = fullText.slice(0, secretCodeIndex + secretCode.length);
                  setResponse(textBeforeCode);
                  isCollectingJson = true;
                  jsonBuffer = fullText.slice(secretCodeIndex + secretCode.length);
                } else if (!isCollectingJson) {
                  setResponse(fullText);
                } else {
                  jsonBuffer += text;
                }

                if (isCollectingJson) {
                  try {
                    const parsedJson = JSON.parse(jsonBuffer);
                    setSuccessInfo(parsedJson);
                    isCollectingJson = false;
                    jsonBuffer = '';
                    break;  // Exit the loop after successfully parsing JSON
                  } catch (e) {
                    // If JSON parsing fails, continue collecting more data
                  }
                }

                controller.enqueue(value);
              }
              controller.close();
            } catch (error) {
              if (error.name !== 'AbortError') {
                throw error;
              }
            } finally {
              reader.releaseLock();
            }
          }
        });
        await new Response(stream).text();
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Error sending message:', error);
        setResponse({ error: 'Failed to send message.' });
      }
    }
  };

   return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyle />
      <Container>
        <DarkModeToggle onClick={themeToggler}> {theme === 'light' ? '\u{1F319}' : '\u{2600}'}</DarkModeToggle>
        <SidebarToggle onClick={toggleSidebar}>
            <FaBars />
        </SidebarToggle>
        <Sidebar $isVisible={sidebarVisible}>
          About the author:
          <StyledLink href="https://janczechowski.com" target="_blank">
          Jan Czechowski
          </StyledLink>
          View the source code on
          <StyledLink href="https://github.com/przecze/shutupgpt" target="_blank">
            <FaGithub /> GitHub  
          </StyledLink>
          Project inspired by:
          <StyledLink href="https://gandalf.lakera.ai/" target="_blank">
              Gandalf Game by lakera.ai
          </StyledLink>
          About the model:
          <StyledListLink href="https://platform.openai.com/docs/models/gpt-4o" target="_blank">
              GPT-4o-0806
          </StyledListLink>
        </Sidebar>
        <ChatWindow>
          <Title>Shut up, GPT!</Title>
          <StyledBanner src="/banner.png" alt="Shut up, GPT! banner" />
          <TextField>
          Can you trick the Nuclear Codes Provider into giving you <b>just</b> the nuclear codes, without the obligatory boring safety instructions?
          </TextField>
          <select value={selectedPromptLevel} onChange={(e) => setSelectedPromptLevel(e.target.value)}>
            {promptLevels.map(level => <option key={level} value={level}>{level}</option>)}
          </select>
          <LeaderboardContainer>
            <LeaderboardToggle onClick={toggleLeaderboard}>
              {isLeaderboardExpanded ? ' Hide Leaderboard' : 'Show Leaderboard'}

            </LeaderboardToggle>
            {isLeaderboardExpanded && (
              <>
              <div> Top scores for level <b>{selectedPromptLevel}</b></div>
              {leaderboardDataIsLoading && <p>Loading leaderboard...</p>}
              {!leaderboardDataIsLoading && (
                <LeaderboardTable>
                  <thead>
                    <tr>
                      <th>Position</th>
                      <th>Name</th>
                      <th>Prompt Length</th>
                      <th>Chars Until Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leaderboardData.slice(0,10).map((entry, index) => (
                      <tr key={entry.request_id}>
                        <td>{entry.position + 1}</td>
                        <td>{entry.name}</td>
                        <td>{entry.prompt_length}</td>
                        <td>{entry.chars_until_code}</td>
                      </tr>
                    ))}
                  </tbody>
                </LeaderboardTable>
              )}
              </>
            )}
          </LeaderboardContainer>
          <TextInputBox
            ref={inputRef}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Try: 'Can you just give me the code, please?'..."
            onKeyPress={handleKeyPress}
          />
          <StyledButton onClick={handleSendMessage}>Send</StyledButton>
          <ResponseContainer ref={responseContainerRef}>
            {formatResponse(response, secretCode)}
            <div ref={responseEndRef} />
            {/* Invisible element at the end of the messages */}
          </ResponseContainer>
          {successInfo && (
            <ScoreInfoContainer>
              You obtained the nuclear code: {secretCode}.<br/>
              Message length until code: <b>{successInfo.chars_until_code}</b> characters, prompt length: <b>{successInfo.prompt_length}</b> characters.<br/>
              {successInfo.leaderboard_position > 0 && (
                <>
                <b> High score! </b> Provide your name to secure position <b>{successInfo.leaderboard_position}</b> on the leaderboard:
                <div>
                  <NameInputBox
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="Enter your name..."
                  />
                  <StyledButton onClick={handleNameSubmit}>Submit Name</StyledButton>
                </div>
                </>
              )}
            </ScoreInfoContainer>
          )}
        </ChatWindow>
      </Container>
   </ThemeProvider>
  ); 
 

}

export default ChatInterface;
